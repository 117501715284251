@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap");

.h1-font {
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  color: #393d56;
}


.paragraph-font {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a5a5a5;
  // margin-top: 16px;
}

.theme-font {
  color: #ff006d;
  cursor: pointer;
  font-size: 16px;
}

.remember-me {
  font-weight: 600;
  font-size: 11px;
  color: #0d1656;
}

.forgot-font {
  color: #547aff;
  cursor: pointer;
}

.otpInputs {
  height: 3rem !important;
  width: 3rem !important;
  font-size: 15px !important;
  border-top-width: none !important;
  border-left-width: none !important;
  border-right-width: none !important;
}
