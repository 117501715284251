.task-layout {
    width: 100%;
    // height: 50px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
}


.tasklayout-text {
    font-size: 15px !important;
}

.backlog-values {
    padding: 5px;
    display: none;
}

.backlog-values.open {
    display: block;
}

.dropdown-item {
    padding: 5px;
}

.backlog-item {
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    display: flex;
    height: 40px;
    border-radius: 4px;
}

.backlog-item:hover {
    background: #e8eaf6;
    cursor: pointer;
}

.dropdown-item:hover {
    cursor: pointer;
    background-color: #ffffff;
}

.dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
    opacity: 1;
}

.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
}

.icon.open {
    transform: rotate(90deg);
}

.all-item {
    width: 8.25rem;
    background: #fff;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .16);
    box-shadow: 0 0 4px rgba(0, 0, 0, .16);
    border-radius: .5rem;
    margin-left: .5rem;
    margin-top: 0rem;
    position: absolute;
    z-index: 2;

}
.inputtype-text {
    width: 100%;
    border: none;
}
.inputtype-text:hover{
    background-repeat: no-repeat;
    border: none;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
}

.sprint__move__to {
   
}