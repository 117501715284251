.calendar-wrap {
  .p-datepicker table td > span.p-highlight {
    color: #282f32 !important;
    position: relative !important;
    background: transparent !important;
  }
  .p-datepicker table td > span.p-highlight::after {
    width: 7px !important;
    height: 7px !important;
    background: #ff006d !important;
    border-radius: 50% !important;
    position: absolute !important;
    bottom: 0px !important;
    content: "";
  }

  .p-datepicker table td > span {
    color: #282f32 !important;
    position: relative !important;
  }
  .p-datepicker table td > span::after {
    width: 7px;
    height: 7px;
    background: #5a78ee;
    border-radius: 50%;
    position: absolute !important;
    bottom: 0px !important;
    content: "";
  }

  .p-datepicker table td.p-datepicker-today > span {
    background: #ff006d;
    color: #ffff !important;
    border-radius: 10px;
    height: 3rem;
  }
  .p-datepicker table td.p-datepicker-today > span::after {
    width: 7px;
    height: 7px;
    background: #ffff;
    border-radius: 50%;
    position: absolute !important;
    bottom: 0px !important;
    content: "";
  }
  .p-disabled::after {
    content: none !important;
  }
  .p-datepicker-title {
    pointer-events: none !important;
  }
  .p-calendar .p-datepicker {
    width: 92% !important;
    min-width: unset !important;
  }
}

.holiday-highlight-calendar {
  width: 40px;
  height: 40px;
  background-color: #f1c40f;
  color: #ffffff;
  border-radius: 50%;
  // width: 2rem;
  // height: 2rem;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar_txt {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #252b30;
}
