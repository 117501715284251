.outer {
  background: #edefff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.inner {
  border-radius: 10px;
  position: absolute;
}

.outer .inner{
    transition: width 1s ease-in-out;
}