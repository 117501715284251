.status-approval-bg {
    height: 50px;
    background-color: antiquewhite;
    border-radius: 4px;
    width: 95%;
}

.status-header-font {
    font-weight: 700;
    font-family: "Poppins" !important;
    color: #252B30;
    font-size: 14px;
}

.status-name-font {
    font-family: "poppins";
    font-weight: 500;
    color: #A4A8AE;
    font-size: 13.5px;
}

.timesheet-action {
    margin-left: -12px !important;
}
.description-box {
    height: 100px !important;
}