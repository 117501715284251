.client-tooltip {
    color: green !important;
    height: 50px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    margin-left: 8px;
    padding-top: 5px;
}
.blue-tooltip.p-tooltip .p-tooltip-text {
    background-color: #5A78EE;
}
.memberround{
    background: #5A78EE;
    color: white;
    width: 28px !important;
    border-radius: 60%;
    height: 29px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    margin-left: -7px;
}
.avatarplus{
    margin-left: -7px;
}
.sprint-progressbar{
    height: 15px;
    width: 56%
}
.p-progressbar-label {
    color: #FAFCFF !important;
    font-size: 10px;
    margin-top: -4px;
}

.dataTable__Text {
    color: var(--black) !important;
}
.p-avatar.p-avatar-image {
    width: 33px !important;
    height: 33px !important;
    margin-left: -8px !important;
}
.plus-button {
    margin-left: -8px !important;
}

.Alphabet-letter{
    margin-left: -9px !important;
}

.projectmembers-action{
      margin-left: -20px !important;
}
.members-group{
    margin-left: 8px !important; 
}


