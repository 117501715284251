.select-plan-box {
  border-style: solid;
  border-width: 1px;
  border-color: #ced4da;
  border-radius: 6px !important;
  height: 54px;
}

.dollar-font {
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-transform: capitalize;
  color: #ff006d;
}

.p-slider.p-slider-horizontal {
  height: 1rem !important;
}

.p-slider .p-slider-range {
  background: #ff006d !important;
}
.p-slider .p-slider-handle {
  border: 2px solid #ff006d !important;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: white !important;
}
.p-slider-horizontal .p-slider-range {
  border-radius: 6px !important;
}


.plan-price-member-font {
  font-weight: 600;
  font-size: 14px;
  color: #393d56;
}

.plan-font {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a5a5a5;
}

.subscription-plan {
  height: 50px;
  border: 1px solid #d1d5db;
  border-radius: 3px;
}

.plan-amount {
  font-weight: 700;
  font-size: 18px;
  color: #ff006d;
}

.plan-type {
  color: #393d56;
  font-weight: 600;
  font-size: 14px;
}

.select-plan {
  color: #393D56;
  font-size: 1.7rem;
  font-weight: 700;

}

