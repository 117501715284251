.project-card {
    // width: 16rem;
    // height: 170px;
    background-color: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #F2F4F5;
    // display: flex;
}

.dragging {
    /* Add some visual feedback to show that the element is being dragged */
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    opacity: 0.8;
  
    /* Optionally, you can animate the element to give a smooth dragging effect */
    transition: all 200ms ease-out;
  }


.colorcard1 {
    background: #988BEC;
    width: 20%;
    height: 14px;
    border-radius: 10px;
}
.colorcard2 {
    background: #8BC9EC;
    width: 20%;
    height: 14px;
    border-radius: 10px;
}
.project-title h1 {
    font-weight: 700;
    font-family: "Poppins" !important;
    color: #252B30;
    font-size: 15px
}
.project-title p {
    font-family: "poppins";
    font-weight: 500;
    color: #A4A8AE;
    font-size: 13.5px;
}
.project-response {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.low {
    text-align: center;
    background: rgba(35, 173, 27, 0.12);
    padding: 4px 10px;
    color: #23AD1B;
    border-radius: 10px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 13.5px;

}
.medium {
    text-align: center;
    padding: 9px 12px;
    color: #D1AE33;
    background: rgba(209, 174, 51, 0.12);
    border-radius: 10px;
    font-family: "Poppins";
    font-weight: 600;
}
.important {
    text-align: center;
    padding: 9px 12px;
    color: #FC3636;
    background: rgba(252, 54, 54, 0.12);
    border-radius: 10px;
    font-family: "Poppins";
    font-weight: 600;

}
.project-image {
    background: green;
    color: white;
    width: 60px;
    border-radius: 60%;
    height: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
} 

