.p-inputtext {
  height: 3rem !important; 
  width: 100% !important;
}
.p-dropdown .p-dropdown-label{
 padding: 0.5rem 0.75rem !important;
}
.p-inputtext:enabled:hover {
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(45deg, #3284ff, #fe2b5e) 1 !important;
  border-radius: 6px !important;
}
.p-inputtext:enabled:focus {
  box-shadow: unset !important;
  border-image: linear-gradient(45deg, #3284ff, #fe2b5e) 1 !important;
  border-color: unset !important;
  border-radius: 6px !important;
}

.p-calendar {
  display: unset !important;
}
.p-dropdown {
  width: 100% !important;
}
.p-password {
  width: 100% !important;
}