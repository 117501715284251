.role-font {
  color: #4c4f5d !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.role-label-font {
    color: #4c4f5d !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.role-table-box {
    border: 1px solid #ced4da;
    border-radius: 6px;
    margin-top: 25px !important;

}
.create-role-alignment {
  margin-left: 39rem;
  margin-top: 25px;
}
// .delete-role-alignment {
//   margin-left: 48rem;
//   margin-top: 25px;
// }
