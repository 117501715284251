

.step-number {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 3px solid #d1d5db;
}
.step-label {
  font-size: 16px;
}
.activeBorder {
  border: 3px solid #ff006d !important;
}
.border {
  border: 3px solid #d1d5db;
}
.txt {
  font-size: 16px;
  font-weight: 600;
  color: #111127;
}
.activeText {
  color: #ff006d;
  font-size: 16px;
  font-weight: 600;
}
.activeSemiCircle {
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: #ff006d;
  border-radius: 50%;
}
.semiCircle {
  display: none;
}

.step-bg {
  background-color: #ff006d;
  border-color: #ff006d ;
}

.step-divider {
  border: none;
  height: 2px;
  background-color: #D1D5DB;
  width: 90px;
  margin: 0;
}
.step-theme-color{
  background-color: #ff006d !important;
}