.card {
  /* Add shadows to create the "card" effect */
  margin-bottom: 2rem;
  margin: 1rem;
}


/* Add some padding inside the card container */
.container {
  padding: 10px;
  background: #ffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 80%;
}
.comments-badge {
  width: 0.938rem;
  height: 0.938rem;
  border-radius: 3.125rem;
  background: #ff8400;
  font-size: 0.5rem;
  color: #ffffff;
  justify-content: center;
  position: absolute;
  top: -0.375rem;
  left: 0.625rem;
}
.comments-count {
  margin-top: 0.25rem;
}

.announcement-header-font {
  color: #4c4f5d !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
}
.announcement-action-btn {
  margin-top: -60px;
  margin-right: -6px;
}
.calender-icon {
  margin-top: 10px;
}

.announcement-time {
  color: #4c4f5d !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

}
// .comments-align {
//   margin-top: -26px;
//   margin-left: 58px;
// }
.comments-time-align {
  margin-left: 8rem;
}
.edit-profile-font {
  color: #5143d4 !important;
}

.DateTime {
  padding: 5px;
  background: rgba(177, 177, 177, 0.12);
  border-radius: 4px;
  color: #8C8C8C;
  font-size: 11px;
}

.time__font{
  font-size: 14px;
}