.table_content_txt {
  color: #000210;
  font-size: 14px;
}
.right_arrow {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  cursor: pointer;
}
#upload_img {
  width: 65px;
  height: 65px;
  background: #f8f7f7;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
}
.upload_txt {
  color: rgba(90, 120, 238, 1);
  text-decoration-line: underline;
  font-size: 14px;
}

.remove_txt {
  color: rgba(255, 0, 109, 1);
  text-decoration-line: underline;
  font-size: 14px;
}

.tooltip_container {
  width: 250px;
  min-height: 80px;
  border-radius: 6px;
  border: none;
}

.tooltip_bg {
  background-color: rgba(8, 23, 53, 1);
  color: rgba(8, 23, 53, 1);
}

.category-create-btn {
  // margin-top: -52px !important;
  margin-right: 42px;
}
.mh_text {
  color: #000210;
  font-weight: 500;
  font-size: 14px;
}
.mh_label_name {
  color: #82858d;
  font-weight: 500;
  font-size: 14px;
}
