.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #fe2b5e !important;
  background: #fe2b5e !important;
}

.p-checkbox .p-checkbox-box.p-highlight:enabled:hover {
  border-color: #fe2b5e !important;
  background: #fe2b5e !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #fe2b5e !important;
}
