// .collapsible-div {
//   border: 1px solid #F4F6FA;
//   padding: 10px;
//   margin-bottom: 10px;
//   transition: max-height 0.5s;
//   overflow: hidden;
//   width: 95%;
//   margin-left: 31px;
//   border-radius: 10px;
//   padding-top: 27px;
//   }

button {
  /* Add some styling for the toggle buttons */
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 8px 16px;
  font-size: 16px;
}

.backlog-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  overflow: scroll;
}

.sprint-container {
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  color: #000210;
  font-size: 15px;
  overflow: hidden;
  width: 100%;
  height: 50px;
}
.sprint-header-font {
  color: #000210;
  font-size: 16px;
  font-weight: 700;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  font-size: 14px;
  font-weight: 600;
}
