.sprintOverley{
    height: fit-content;
    max-height: 200px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);
    border: 2px grey;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
}


.backlog-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    overflow: scroll;
}

.accodion {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12);   
}