.folder-date-font {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #777663;
}

.folder-date-align {
  margin-top: -100px;
  margin-left: 14px;
}

.folder-name-font {
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #252b30;
}

.folder-name-align {
  margin-top: 26px;
  margin-left: 19px;
}

.create-folder-input {
  width: 55%;
  margin-top: -5rem;
  margin-left: 1.5rem;
}

.folder-action-btn-design {
  color: #828585 !important;
  border-radius: 23% !important;
  border: 1px solid #fff3b9 !important;
  width: 10px !important;
  height: 10px !important;
}

.folderdocs-action-btn-design {
  color: #828585 !important;
  border-color: transparent !important;
  width: 10px !important;
  border-radius: 23% !important;
  height: 10px !important;
}

.folder-action-btn-align {
  margin-right: -25px;
}

.sidebar-file-header-font {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #82858d;
}

.sidebar-file-choose-btn-font {
  color: #6366f1;
  background: transparent;
  border: 1px solid #6366f1;
  width: 8% !important;
  height: 30px;
  border-radius: 50%;
}

.sidebar-file-upload-btn-font {
  color: #22C55E;
  background: transparent;
  border: 1px solid #22C55E;
  width: 8% !important;
  height: 30px;
  border-radius: 50%;
}

.sidebar-file-cancel-btn-font {
  color: #EF4444;
  background: transparent;
  border: 1px solid #EF4444;
  width: 8% !important;
  height: 30px;
  border-radius: 50%;
}