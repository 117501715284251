
.choose__file {
    color: #6366f1;
    background: transparent;
    border: 1px solid #6366f1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .profile__img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

