.scrollable {
  width: 511px;
  height: 225px !important;
  overflow: auto;
  text-align: justify;
  padding: 20px;
}
.img-square {
  background: #5a78ee;
  color: white;
  width: 35px;
  border-radius: 18%;
  height: 36px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  margin-top: -5px;
  margin-left: -3px;
}

.leave-scroll {
  margin-top: 8px;
  height: 268px;
  overflow: auto;
  text-align: justify;
  padding: 20px;
  border: 1px solid #f5f9ff;
  border-radius: 3% !important;
  background: #ffff;
}

.tomorrow-leave-box {
  margin-left: -24px !important;
  width: 24.5rem !important;
  // height: 23rem !important;
}

.img-size {
  width: 33px;
  border-radius: 50%;
  height: 33px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.today-leave {
  margin-top: -42px !important;
  padding: 27px;
}
.leave-reason-font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #82858d;
}
