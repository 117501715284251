.add-members-font {
  font-family: "poppins";
  font-weight: 500;
  color: #a4a8ae;
  font-size: 13.5px;
}

.members-info-round {
  background: #5a78ee;
  color: white;
  width: 100px !important;
  border-radius: 60%;
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  font-size: 40px;
}

.members-info-image {
  width: 100px;
  border-radius: 50%;
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-owner-image {
  width: 33px;
  // border-radius: 20%;
  height: 33px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.members-action {
  margin-left: -60px;
}

.members-list-table-border {
  border: 1px solid #ced4da;
  border-radius: 6px;
  min-height: 69vh;
  overflow-y: scroll;
}

.field__name {
  font-weight: 600;
  font-size: 0.75rem;
}
