.primary {
  background: #ff006d !important;
  border-radius: 1.5rem !important;
  border: 0px !important;
  padding: 0.45rem 1.5rem !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Poppins" !important;
}

.secondary {
  background: #5a78ee !important;
  border-radius: 0.5rem !important;
  border: 0px !important;
  padding: 0.8rem 1rem !important ;
  font-family: "Poppins" !important;
}

.filter-button{
  background: transparent;
  color: #585960;
  border-radius: 6px !important;
  border: 1px solid #EFF1F5 !important;
  padding: 0.45rem 1.5rem !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Poppins" !important;
  margin-right: 11px !important;
}