.scratchpad-header-font {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #252b30;
}

.scratchpad__nav {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #82858d;
}

.scratchpad-font {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #82858d;
}
.scratchpad-card-font {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #82858d;
}
.scratchpad-dialog-border {
  border: 1px solid #f5f9ff;
  border-radius: 4px !important;
  background: #ffff;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.scratchpad-round {
  background: #5a78ee;
  color: white;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scratchpad-profile-pic {
  width: 28px;
  height: 29px;
  border-radius: 50%;
  margin-top: -5px;
}

.scratchpad-body-font {
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #252b30;
}

// refactorings

.scratchpad__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #252b30;
}

.p-editor-container .p-editor-toolbar.ql-snow{
  border: unset !important;
}
.ql-container.ql-snow{
 border: unset !important;
}
.p-editor-container .p-editor-content.ql-snow{
  border: unset !important;
}

.p-editor-container .p-editor-toolbar{
  background: unset !important;
}