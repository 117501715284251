.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600 !important;
  font-size: unset !important;
}

label {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #82858d;
}
