// .View {
//     width: 14px !important;
//     height: 14px !important;

//     background: #02D5A3 !important;
//     border-radius: 50% !important;
//     margin: 15px !important;
// }

// .Edit {
//     width: 14px !important;
//     height: 14px !important;

//     background: #F27E11 !important;
//     margin: 15px !important;
// }

// .Admin {
// 	width: 0;
// 	height: 0;
// 	border-left: 14px solid #FF006D !important ;
// 	border-right: 14px solid #FF006D !important;
// 	border-bottom: 14px solid !important;

//     background: #FF006D !important;
//     margin: 15px !important;
// }

.role-label {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #252b30;
}
.view-label-align {
  margin-left: -100px;
}
.edit-label-align {
  margin-left: 26px;
}
.admin-label-align {
  margin-left: 24px;
}
.module-font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #82858d;
}
.module-align {
  margin-left: 35px;
}
.role-type-align {
  margin-left: 44px;
  margin-top: 20px;
}
.settings-action-align {
  margin-left: -10px;
}
.role-action {
  margin-left: 80px;
  height: 20px;
}
.role-table-border {
  border-bottom: 1px solid #e7e9f2;
  margin-left: 6px !important;
  padding-bottom: 16px;
}
.settings-sidebar {
  display: flex;
}
