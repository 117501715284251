.add-sub-tasks {
  background: rgba(13, 22, 86, 0.06);
  margin-top: 1rem;
  border-radius: 0.25rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #0d1656;
  width: 100%;
}

.task-comments-font {
  color: #4c4f5d !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.task-scrollable {
  height: 600px !important;
  overflow: auto;
  text-align: justify;
  padding: 2px;
}
.left-task-scrollable {
  height: 600px !important;
  overflow: auto;
  text-align: justify;
  padding: 2px;
}
.input-task-btn {
    margin-top: -50px !important;
    margin-right: 7px !important;
  }
.task-desc {
  z-index: 99 !important;
}  
.editor-border {
  border-radius: 6px;
  border: 1px solid #ced4da;
}