.bugtask-layout{
    background: rgba(0, 0, 0, 0.02);
    border-radius: 0.25rem;
    margin-top: 10px;
    width: 100% !important;
}
.bug-description{
    color: rgb(0, 2, 16);
    font-size: 0.938rem;
    line-height: 1.563rem;
    word-break: break-all;
}

.bug-date {
    color: rgb(128, 129, 139);
    font-size: 0.75rem;
    text-align: center;
    margin-left: 6px;
}
.bug-round {
    width: 20px !important;
    margin-top: -3px !important;
    height: 20px !important;
}
.bug-name {
    color: rgb(128, 129, 139);
    font-size: 0.75rem;
    font-weight: 700;
    text-align: center;
}

.add-link-font {
    color: blue !important;
    font-size: 15px;
    font-weight: 600;
}

.file-upload-btn {
    height: 30px !important;
    width: 30px !important;
    // margin-right: 1rem !important;
}
.preview__img{
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.02);
    color: blue !important;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid #ced4da;
    border-radius: 12px;
}

.link-layout{
    padding: 10px;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    border: 1px solid #ced4da;
}
