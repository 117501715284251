.scratchpad-card-header-font {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: #252b30;
}
.scratchpad-card-font {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #82858d;
  word-wrap: break-word;
}

.add-new-note {
  height: 50px;
  display: flex;
  justify-content: center;
  column-gap: 0.5rem;
  align-items: center;
  background: #ffffff;
  border: 1px solid #eff1f5;
  border-radius: 4px;
}

.add_new__txt {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #585960;
}

.scratchpad-card-border {
  border: 1px solid #eff1f5;
  border-radius: 4px;
}

.ql-snow .ql-picker-options {
  background-color: #fff;
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  min-height: 160px !important;
  white-space: nowrap;
}

