.checkbox-layout {
  // width: 80%;
  // height: 3.7rem;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
}
.backlog-titile {
  margin-left: 1.655rem;
  margin-top: 8px;
  color: #4c4f5d;
}
.backlog-para {
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 11px;
  font-size: 13px;
  font-weight: 600;
  width: 54%;
  height: 40px;
}
.tasks-round {
  color: #0d1656;
  background: #dfe1e6;
  border: 1px solid #dfe1e6;
  cursor: pointer;
  width: 28px !important;
  border-radius: 60%;
  height: 29px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.to-do {
  width: 32px;
  margin-top: -2px;
  cursor: pointer;
}

.sprint-task-alignment {
  margin-left: 9rem;
}
.backlog-paginate-dropdown {
  margin-left: 32rem;
}
.backlog-movetask-btn {
  height: 36px;
  width: 11%;
  background-color: #5a78ee;
  color: #ffff;
  font-weight: 600;
  font-size: 13px;
  border-radius: 6px;
}
.sprint-task-round {
  background: #5a78ee;
  color: white;
  width: 32px !important;
  border-radius: 60%;
  height: 33px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sprint-start-btn {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.tasks__round__count {
  background: #dfe1e6;
  border: 1px solid #dfe1e6;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  color: #0d1656;
  padding: 10px;
}

.tasks__count {
  padding: 10px;
  background: #dfe1e6;
  border: 1px solid #dfe1e6;
  max-width: 150px;
  height: 30px;
  border-radius: 50px;
  color: #0922e0;
   font-weight: bold;
}


.backlog__movetask__btn {
  background-color: #5a78ee;
  width: 100px;
  color: #ffffff;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
}
