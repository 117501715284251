.release-card-size {
  width: 324px;
  height: 171px;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  background-color: #ffff;
  margin-left: 20px;
}
.active-release-card-size {
  background: #f5f5f0;
  border: 1px solid #eff1f5;
  width: 324px;
  height: 171px;
  border-radius: 10px;
  margin-left: 20px;
}

.release-card-header-font {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #252b30;
}
.release-card-desc-font {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #a4a8ae;
}
.endDate-btn {
    width: 100px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #8C8C8C;
    background: rgba(177, 177, 177, 0.12);
    border-radius: 4px;
    padding: 8px;
}

.release-action-btn {
  margin-left: 17rem;
  margin-top: -17px;
}

.release-tableaction {
    padding-left: 5.5rem !important;
  }

  .create-release-btn {
    min-width: 324px;
    height: 171px;
    border: 1px dashed #d2d2d2;
    border-radius: 10px;
  }

