.subtask-layout {
    background: rgba(0, 0, 0, 0.02);
    border-radius: 0.25rem;
    margin-top: 10px;
    width: 100% !important;
}
.taskpercentage-bar{
    background: rgba(0, 0, 0, 0.02);
    border-radius: 0.25rem;
    margin-top: 10px;
    width: 615px;
}
.taskprogress-bar{
    width: 55%;
    background-color: rgba(0, 0, 0, 0.02) ;
    height: 20px;
    border-radius: 12px;
}
.task-description {
    color: rgb(0, 2, 16);
    font-size: 0.938rem;
    line-height: 1.563rem;
    margin-top: -2px;
    word-break: break-all;
}

.task-date {
    color: rgb(128, 129, 139);
    font-size: 0.75rem;
    text-align: center;
    margin-left: 6px;
}

.task-round {
    width: 20px !important;
    margin-top: -3px !important;
    height: 20px !important;
}
.task-name {
    color: rgb(128, 129, 139);
    font-size: 0.75rem;
    font-weight: 700;
    text-align: center;
}
.subtask{
    font-size: 0.875rem;
    color: rgba(0, 3, 23, 0.6);
}
.taskpercent{
    font-weight: 700;
    font-size: 15px;
    color: #23ad1b;
}
.p-progressbar-determinate .p-progressbar-label {
    color: green; 
   
}