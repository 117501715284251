* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
   max-height: 100%;  
}


@import "./css//font.scss";
@import "./css/Authentication/sidebar.scss";
@import "./css/HumanResource/asset-table.scss";

// Common components
@import "./component/common/inputtext/input-field.scss";
@import "./component/common/button/button.scss";
@import "./component/common/checkbox/checkbox.scss";
@import "./component/common/navbar/navBar.scss";
@import "./component/common/chart/chartStyle.css";
@import "./component//human-resource//announcement.scss";
@import "./component//human-resource/announcementCard.scss";
@import "./component//human-resource/recognition.scss";
@import "./component/human-resource/employeeLeaveList.scss";
@import "./component//common//inputIcon//inputIcon.scss";
@import "./component/users/userTable.scss";
@import "./css/checkbox.scss";
@import "./component//timesheet/timesheetTable.scss";
@import "./component/scratchpad/scratchpadcard.scss";
@import "./component//scratchpad//scratchpadEditorDialog.scss";
@import "./component/project/projectListTable.scss";
@import "./component/project/projectstorycard.scss";
@import "./component/project/releaseCardComponent.scss";
@import "./component/project/tasks/updateTaskDialog.scss";
@import "./component/project/tasks/subtask.scss";
@import "./component/project/tasks/taskbugs.scss";
@import "./component/project/projectMember/projectMembersManage.scss";
@import "./component/project/projectTimesheet/projectTimesheetTable.scss";
@import "./component/project/projectRelease/releaseCard.scss";
@import "./component/project/projectRelease/releaseAddTask.scss";
@import "./component/project/projectFiles/folderCard.scss";
@import "./component/project/projectOverView/overview-chart/overviewChart.scss";
@import "./pages/Project/projectBacklog.scss";
@import "./component/dashboard/timeSpent.scss";

:root{
  --white-text : "#ffffff";
  --grey : "#a4a8ae";
  --grey-primary : "#82858D";
  --text-color : #000000;
  --black : "#252B30";
  --active-tab : 2px solid #FF006D;

  --primary-font : 14px;
  --secondary-font : 12px;
  --ternary-font : 10px;
  

  --primary-weight : 600;
  --secondary-weight: 500;
  --border-outline: "1px solid #F2F4F5";
  --border-shadow: "0px 4px 4px rgba(0, 0, 0, 0.03);"

}

