.oviewStyle {
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background: #ffffff;
}

.overView__status {
  width: 200px;
}
.count__color {
  color: whitesmoke;
  font-weight: bold;
}
.progress__title {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

.team__txt {
  color: var(--text-color);
  font-size: 15px !important;
  font-weight: 600 !important;
}
.project-overview-header-round {
    // background: #5a78ee;
    color: white;
    width: 38px;
    height: 38px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    
    // width: 3% !important;
    // padding: 10px;
    // margin-top: -5px;
    // margin-left: -3px;
}
.project-overview-img-round {
    width: 3% !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
    margin-left: -3px;
  }
  .empty-team {
    margin-left: 7rem;
  }

