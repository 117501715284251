.active-round {
  border-radius: 1.5rem !important;
  border: 0px !important;
  padding: 0.6rem !important;
  width: 5rem;
  height: 2.375rem !important;
  text-align: center;
  background: rgba(27, 173, 138, 0.12);
  color: #1bad93;
}
.pending-round {
  border-radius: 1.5rem !important;
  border: 0px !important;
  padding: 0.6rem !important;
  width: 5rem;
  height: 2.375rem !important;
  text-align: center;
  background: rgba(173, 27, 27, 0.12);
  color: #ad1b3e;
}
.projects-round {
  border-radius: 60% !important;
  border: 0px !important;
  padding: 0.6rem !important;
  width: 2.5rem;
  height: 2.375rem !important;
  text-align: center;
  background: rgba(27, 173, 138, 0.12);
  color: #1bad93;
  cursor: pointer;
}
.assigned-project-font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #82858d;
}
.user-add-btn{
  margin-left: 58rem;
}
.user-remove-image-btn {
  margin-bottom: 73px;
  margin-left: 42px;
}
.action-btn-design {
  color: #828585 !important;
  border-radius: 23% !important;
  background: linear-gradient(180deg,#fcfcfc,hsla(0,0%,95.3%,.6)) !important;
  border: 1px solid #e3e3e3 !important;
  width: 10px !important;
  height: 10px !important;

}
.tooltip-font-size {
  font-size: 13px !important;  
}
.label-font {
  font-size: 11px !important;
  color: #828585;
}
.update-user-label-align {
  margin-top: -25px !important;

}
.user-table-action-btn {
  margin-left: -15px !important;
}
.user-image-align {
  margin-top: 10px !important;
}
.custom-dropdown .p-dropdown {
  width: unset !important;
}

.user-choose-btn-font {
  color: #6366f1;
  background: transparent;
  border: 1px solid #6366f1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user-upload-btn-font {
  color: #22C55E;
  background: transparent;
  border: 1px solid #22C55E;
  width: 6% !important;
  height: 30px;
  border-radius: 50%;
}
.user-cancel-btn-font {
  color: #EF4444;
  background: transparent;
  border: 1px solid #EF4444;
  width: 6% !important;
  height: 30px;
  border-radius: 50%;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  height: 25px;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
  height: 19px;
  width: 19px;
  margin-left: 2px;
}
.p-inputswitch .p-inputswitch-slider {
  height: 25px !important;  
}
.p-inputswitch .p-inputswitch-slider:before {
  height: 19px !important;
  width: 19px !important;
}