.board_layout {
  background: #f4f6fa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100vh;
  overflow-y: scroll;
}
.board_plus_box {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #f2f4f5;
  border-radius: 10px;
}

.board_width {
  width: 25%;
}

.board_txt {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}
