

body{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #82858d;
}

.solid {
  border-top: 1px solid #DCE0ED;
}
.Vertical__line {
  border-left: 1px solid #DCE0ED;
}
._bb{
  border-bottom: 1px solid #DCE0ED
}
.project-setting-header {
font-weight: 600;
font-size: 12.5px;
line-height: 21px;
color: #82858D;
}
