.nav-container {
  height: 3.7rem;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 4px #f4f6fa;
  padding: 1rem;
}

.nav-value {
  // width: 100%;
  text-align: center;
  line-height: 21px;
  font-size: var(--primary-font) !important;
}

.active-nav {
  border-bottom: var(--active-tab);
  line-height: 21px;
  font-size: var(--primary-font) !important;
  color: #252B30;
  font-weight: 500;
}

.project__tab {
  font-size: var(--secondary-font);
  color: var(--grey);
  line-height: 18px;
  font-weight: 600 !important;
}

.project__tab__active {
  border-bottom: var(--active-tab);
  font-size: var(--secondary-font);
  color: var(--text-color);
  line-height: 18px;
  font-weight: 600 !important;
}

.box-shadow {
  border-bottom: 1px solid white;
  box-shadow: 0px 4px 4px #f4f6fa;
  height: 4rem !important;
  // align-items: center;
  background-color: #ffffff !important;
}
.nav-bar-sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}

.nav-button {
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding: 0.2rem 1rem;
  border-radius: 7px;
  border: 0px;
  color: #82858d;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  background-color: #ffffff;
}
.active-button {
  background-color: #ff006d;
  color: #ffffff;
}
.active-link {
  color: black;
  font-weight: bold;
}

.nav-logo {
  margin-left: 28px;
  margin-top: 5px;
  width: 40px;
}

.hover {
  cursor: pointer;
}
.profile-pic {
  border-radius: 50%;
  width: 50px;
  height: 75px;
}
.profile-bar {
  margin-left: 7rem;
}
.template-background {
  background: #fafcff;
}
.transperent-bg {
  background: transparent;
}

.setting__role {
  font-size: 14px;
  color: #A4A8AE;
  font-weight: 600;
  cursor: pointer;
}

.setting__active {
  color: #252b30;
}
