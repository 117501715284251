.timesheet-table-header-font {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 11.5px !important;
  line-height: 20px !important;
}
.p-datepicker {
  border: none !important;
}
.status-not-updated {
  background: #f8f8f8;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #252b30;  
  width: 300px;
}

.status-not-updated-dates {
  color: #ff006d;
}
.status-label-font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #252b30;
}

.special-holiday {
  background: #ffb516;
  border-radius: 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffff;
  width: 300px;
}
.timesheet-projects-round {
  background: #5A78EE;
  color: white;
  width: 35px !important;
  border-radius: 50%;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;  
}
.projects-img-size {
  width: 33px;
  border-radius: 50%;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center; 
}
// .holidays-list {
//   margin-top: 10px !important;
//   margin-left: 4.5rem !important;
// }
.read-more-button {
    font-size: 12px;
    color: #000210;
    font-weight: 700 !important;
    margin-top: 7px;
}

.title-notification{
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
}
.announcement{
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  color: #82858d;
}

.today{
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  color: #82858d;
}
.description{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #82858d;
}
.notification-date{
  font-size: 13px ;
  color: #82858d;
  font-weight: 900;
}
.announceBell{
  display: flex;
  align-items: flex-start;
  // justify-content: center;
}

::-webkit-scrollbar {
  height: 2px !important;
  width: 2px !important;
}
.notify__container{
  width: 320px;
  max-height: 300px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 16px rgb(0 0 0 / 16%);
  box-shadow: 0px 0px 16px rgb(0 0 0 / 16%);
  overflow-y: scroll;
  background: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.p-overlaypanel .p-overlaypanel-content{
  padding: unset !important;
}