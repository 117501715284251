.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type=time]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance:none;
  appearance: none;
  margin: -10px; 
}

.update-timesheet-input {
  width: 84% !important;
}

.update-timesheet-box{
  width: 100% !important;
}

.p-panelmenu .p-panelmenu-header > a {
  padding: 0.75rem !important;
  border: 1px solid #dee2e6;
  color: #6c757d;
  background: #ffff !important; 
  font-weight: 500 !important;
  border-radius: 6px;
}
.drop-z-index {
position: relative !important;
bottom: 0px !important;
z-index: 1;
}