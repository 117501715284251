.chip {
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.1rem 0.75rem;
  background-color: #dee2e6;
  color: #495057;
  height: 30px;
}

.chip__low__btn {
  background: rgba(35, 173, 27, 0.12);
  color: #23ad1b;
}
.chip__medium__btn {
  background: rgba(209, 174, 51, 0.12);
  color: #d1ae33;
}
.chip__high__btn {
  background: rgba(252, 54, 54, 0.12);
  color: #fc3636;
}

.chip > span {
  font-weight: 600;
}


.chip-low {
  color: #fafcff;
  background: #02d5a3;
  border-radius: 20px;
  width: 60px;
  height: 30px;
}

.chip-medium {
  color: #fafcff;
  background: #feb800;
  border-radius: 20px;
  width: 60px;
  height: 30px;
}
.chip-high {
  color: #fafcff;
  background: #ff302f;
  border-radius: 20px;
  width: 60px;
  height: 30px;
}
