// scsss variable

$bold-text: #252b30;

.billings__details {
  background: #ffffff;
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.billing__text {
  font-weight: 800;
  font-size: 24px;
  color: $bold-text;
}

.error__theme {
  color: #ff006d;
}

.highlight___text {
  letter-spacing: 0.5px;
  color: #ff006d;
  font-weight: 700;
  font-size: 14px;
}

.info__btn {
  background: #f4f4f5 !important;
  color: #252b30 !important;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  width: 200px;
}
.payment__method {
  color: #252b30;
  font-weight: 600;
  font-size: 1rem;
}

.upgarde__plan {
  width: 200px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.Billing_txt {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #87898a;
}
.Billing_txt__light {
  color: #4c4f5d;
  font-size: 13px;
}
.billing__status{
  
}
