.dashboard-chart-container {
  background: #ffffff;
  padding: 10px;
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 3%);
  border-radius: 10px;
  align-items: center;
  height: 100%;
  margin-top: -16px;
}
.dashboard-profile-container {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  height: 500px;
  overflow: scroll;
}
.dashboard-profile-calendar {
  width: 100% !important;
  height: auto;
  background: #f8f8f8;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ff006d;
}

.drop-chart {
  border: 0 !important;

  .p-inputtext {
    height: 20px !important;
    color: #ff006d !important;
    font-size: 12px !important;
    padding: unset !important;
  }

  .p-dropdown .p-dropdown-label {
    padding: 7px !important;
  }

  .p-dropdown-trigger {
    width: 15px !important;
  }

  .pi {
    font-size: 0.5rem;
    color: #8f95b2;
  }
  .p-dropdown-items {
    padding: 4px !important;
  }
  .p-dropdown:not(.disabled).p-focus {
    box-shadow: none !important;
  }
}

span {
  font-size: 12px;
}

.dash-toggle {
  .p-inputswitch .p-inputswitch-checked .p-inputswitch-slider {
    background: #ff006d !important;
  }
}

.circle {
  width: 80px;
  height: 80px;
  background-color: #ff006d;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-circle {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: yellow;
}
