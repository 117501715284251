.cardname {
  border: none;
  color: #aab7c4;
  font-size: 1rem;
  height: 45px !important;
}

input {
  height: 2rem !important;
}

.label-style {
  color: #111127;
  font-weight: 400;
  font-size: 16px;
}

.info__back__btn {
  background: #f4f4f5 !important;
  color: #252b30 !important;
  border-radius: 20px;
  font-weight: 600;
  font-size: 13px;
  width: 100px;
  height: 35px;
}

.card-number-box {
  padding: 12px;
  border: 1px solid lightgrey;
  border-radius: 7px;
}
