.release__title {
  font-size: 12px;
  font-weight: 600;
  color: #252B30;
}
.release__endate {
  padding: 5px;
  background: rgba(177, 177, 177, 0.12);
  border-radius: 4px;
  color: #8C8C8C;
  font-size: 11px;
}
.release__desc{
    color: #A4A8AE;
    font-size: 12px;
}
.release__card {
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background: #ffffff;
}

.p-carousel .p-carousel-indicators {
  display: none;
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
  background-color: #FFFFFF !important;
  color: #161719 !important;
}
