.p-button{
    padding: 5px !important;
}

body{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #82858d;
}