.out-line {
  border: 1px solid #eff1f5;
}

.wiki__text__color {
  color: #252b30 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.page__name__width{
  min-width: 100px;
}

.addPage-box {
  border: 1px solid #eff1f5;
  border-radius: 5px;
  width: 150px;
  height: 150px;
  cursor: pointer;
}
.add__space {
  border: 1px solid #eff1f5;
  border-radius: 5px;
  height: 48px;
  cursor: pointer;
}

.checking-color {
  background-color: #eff1f5;
  border-radius: 5px;
  border-left-style: inset !important;
  border-left-color: #6366f1 !important;
}

.checking-color-active {
  padding: 8px;
  background-color: #eff1f5;
  border-radius: 5px;
  border-left-style: inset !important;
  // border-left-color: #ff006d !important;
}

.spacetree__container {
  border-radius: 3px;
  height: 40px !important;
}

// input[type="text"] {
//   height: 40px !important;
// }

input[type="text"]:focus {
  outline: none !important;
}

.hover-list:hover {
  background-color: rgb(232, 234, 246);
  border-radius: 5px;
}

.font {
  font-size: 1rem;
}

.overlaypanel {
  width: "200px";
  height: "auto";
}
