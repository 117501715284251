.dashboard-calendar-wrap {
  .p-datepicker table td > span.p-highlight {
    color: #282f32 !important;
    position: relative !important;
    background: transparent !important;
    font-size: 0.75rem;
    margin: 0.5rem 0;
  }
  .p-datepicker table td > span.p-highlight:after {
    background: #ff006d !important;
    border-radius: 50% !important;
    bottom: -1px !important;
    content: "";
    font-size: 0.65rem;
    height: 4px !important;
    margin: 0.05rem 0;
    position: absolute !important;
    width: 4px !important;
  }
  .p-datepicker table td > span {
    color: #282f32 !important;
    position: relative !important;
  }
  .p-datepicker table td > span {
    width: unset !important;
    height: unset !important;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
  }
  .p-datepicker table {
    font-size: 0.85rem !important;
  }

  .p-datepicker table td > span::after {
    width: 4px;
    height: 4px;
    background: #5a78ee;
    border-radius: 50%;
    position: absolute !important;
    bottom: 0px !important;
    content: "";
  }

  .p-datepicker table td.p-datepicker-today > span {
    background: #ff006d;
    color: #ffff !important;
    border-radius: 10px;
    height: 2.5rem !important;
  }
  .p-datepicker table td.p-datepicker-today > span::after {
    width: 4px;
    height: 4px;
    background: #ffff;
    border-radius: 50%;
    position: absolute !important;
    bottom: 0px !important;
    content: "";
  }
  .p-disabled::after {
    content: none !important;
  }
  .p-datepicker-title {
    pointer-events: none !important;
  }
  .p-calendar .p-datepicker {
    width: 92% !important;
    min-width: unset !important;
  }
}
.dashboard-holiday-round {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
