.side__text {
  font-size: 20px;
  color: #ffffff;
  font-weight:600
}
.side__desc {
  font-size: 26px;
  color: #ffffff;
  font-weight: 600;
  line-height: 36px;
}

.sideBar {
  background: #F5056C;
  background-image: url("https://i.ibb.co/zNRQSv9/frame.png");
  mix-blend-mode: normal;
  height: 100vh;
}

@media screen {
    
}