.avatar-container {
  border-radius: 20px;
  width: 135px;
  height: 39px;
}
.requested-font {
  color: #df9e20 !important;
  // margin-left: 9px;
  font-weight: 600;
  font-size: 13px !important;
}
.occupied-font {
  color: #42b6cd !important;
  // margin-left: 9px;
  font-weight: 600;
  font-size: 13px !important;
}
.declined-font {
  color: #ff0000 !important;
  // margin-left: 9px;
  font-weight: 600;
  font-size: 13px !important;
}
.icon {
  background: #5143d4 !important;
}
.p-button-rounded {
  border-radius: 50%;
  width: 24px !important;
  // height: 0px !important;
  // margin-top: -2px !important;
  // margin-left: -6px !important;
}
.AvatarFont {
  // color: #5143d4;
  font-weight: 600;
  font-size: 13px;
  // margin-left: 26px;
  // margin-top: -25px;
}

.round {
  background: #5a78ee;
  color: white;
  width: 36px;
  border-radius: 50%;
  height: 36px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leave-request-round {
  background: #5a78ee;
  color: white;
  width: 36px !important;
  border-radius: 60%;
  height: 36px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.asset-round {
  background: #5a78ee;
  color: white;
  padding: 10%;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .asset-round-align {
//   margin-left: -5px;
//   margin-top: -5px;
// }
.red {
  background: #ffe2e2;
  color: black;
}
.green {
  background: #e1f0f3;
}
.blue {
  background: #ececfc;
}
.requested-bg {
  background-color: #fff6e6 !important;
}
.leave-status-align {
  margin-left: 20px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
}
.approved-btn {
  border-radius: 1.5rem !important;
  border: 0px !important;
  padding: 0.6rem !important;
  width: 8rem;
  height: 2.375rem !important;
  background: #e1f0f3;
}
.pending-btn {
  background: #fff6e6 !important;
}

.declined-btn {
  background: #ffe2e2 !important;
}
.asset-owner-image {
  width: 25px;
  border-radius: 80px;
  height: 29px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: ;
}

.overlay_style {
  width: 240px;
  height: auto;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.overlay_txt {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 2, 16, 1);
}
