* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.search-input {
  width: 23rem !important;
  margin-left: 27px !important;
}
.drop-input {
  width: 12rem;
  height: 3rem !important;
}
.recognition-create {
  height: 2.5rem !important;
  margin-top: 2px;
  margin-right: 35px;
  background: #ff006d !important;
  border-radius: 1.5rem !important;
  border: 0px !important;
  padding: 0.45rem 2.5rem !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Poppins" !important;
}

.all-recognition-box {
  border: 1px solid #f5f9ff;
  border-radius: 3% !important;
  background: #ffff;
}
.empty-all-recognition-box {
  height: 267px !important;
  background: #ffff;
}
.all-tab-recognition-image {
  width: 100px !important;
  height: 100px !important;
  border-radius: 9% !important; 
  display: flex !important;
  margin-left: 4rem;
}
.all-tab-recognition-box {
  background: #5a78ee;
  color: white;
  width: 57px;
  border-radius: 9%;
  height: 36px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 53px !important;
  margin-top: -26px;
  margin-left: 4rem !important;
}

.blue-balloon {
  margin-top: -8rem;
  margin-left: -11rem;
  height: 58px;
}
.red-balloon {
  margin-top: -6rem;
  margin-left: 9rem;
  height: 58px;
}
.orange-balloon {
  margin-left: 9rem;
  height: 58px;
}
.leaf {
  height: 50px !important;
}

// .p-carousel-container {
//   display: unset !important; 
// }
// .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
//    width: 0 !important;
//    height: 0 !important; 
//    color: white !important;
//    border: 0 none !important;
//    background: transparent !important; 
//    border-radius: 0% !important; 
//    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s; 
//    margin-top: -25px !important; 
// }
.recognition-action-btn {
  margin-left: 16rem !important;
  margin-top: -44px !important;
 }
 .leave-approve-dialog-btn {
  margin-left: 11rem !important;
 }