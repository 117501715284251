input:focus::placeholder {
  color: transparent;
}

 input {
  height: 3.5rem !important;
}
input:enabled:hover {
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(45deg, #3284ff, #fe2b5e) 1 !important;
  border-radius: 6px !important;
}
input:enabled:focus {
  box-shadow: unset !important;
  border-image: linear-gradient(45deg, #3284ff, #fe2b5e) 1 !important;
  border-color: unset !important;
  border-radius: 6px !important;
}
.p-float-label {
  display: inline-block;
}
