.release-status-inprogress {
  font-size: 14px;
  color: #f27e11;
  font-weight: 600;
}
.release-status-done {
  font-size: 14px;
  color: #28b425;
  font-weight: 600;
}
.release-status-review {
  font-size: 14px;
  color: #9428ff;
  font-weight: 600;
}
.release-status-todo {
  font-size: 14px;
  color: #969696;
  font-weight: 600;
}

.release-priority-low-btn {
  border-radius: 22px;
  height: 30px;
  width: 70px;
  font-size: 12px;
  background: #02d5a3;
  color: #fafcff;
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.release-priority-medium-btn {
  border-radius: 22px;
  height: 30px;
  width: 70px;
  font-size: 12px;
  background: #feb800;
  color: #fafcff;
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.release-priority-high-btn {
  border-radius: 22px;
  height: 30px;
  width: 70px;
  font-size: 12px;
  background: #ff302f;
  color: #fafcff;
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
}
