.scrollable {
  width: 6px;
  height: auto !important;
  overflow: auto;
  text-align: justify;
  padding: 2px;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.announcement-border {
  border: 1px solid #f5f9ff;
  border-radius: 3% !important;
  width: 32rem;
  height: 17rem;
  margin-left: 33px;
  margin-top: 7px;
  background: #ffff;
}
.no-leave-tomorrow {
  margin-left: 17px !important;
  margin-top: 15px !important;
  width: 24.5rem !important;
}
.no-leave-today {
  margin-top: 8px !important;
  width: 24.5rem !important;
}
.create-btn {
  background-color: #5a78ee;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  padding: 9px 25px !important;
  gap: 10px;
  font-weight:600;
  font-size: 12px;
}
