.asset-table-size {
  max-width: 100% !important;
  margin-left: 35px;
}

.asset-table-font {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
}
.assets-actions {
  margin-top: 0rem !important;
}
.p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #82858d !important;
  background: #ffffff !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  color: #000210;
  padding: 1rem 1rem;
}
.table-box {
  width: 95%;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-left: 1.5rem;
}
.payslip-table-box {
  // width: 94%;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: 0 2.5rem;
}
.payslip_head{
  font-size: 20px;
}
.payslip_head{
  font-size: 16px;
  font-weight: 600;
  width: 150px;
  color:#000000;
}
.filter_section{
  text-align: right;
  display: flex;
}
.section{
  display: flex;
  justify-content: space-between;
  gap: 54rem;
}
.dropdown_input{
  width: 10rem;
    height: 2.5rem !important;
}


.p-speeddial-action {
  width: 2rem;
  height: 2rem;
  background: #495057;
  color: #fff;
}
.p-speeddial {
  position: unset !important;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 37px !important;
  height: 39px !important;
}

.asset-action {
  margin-left: -27px !important;
}
.leave-policy-table {
  margin-left: 0px !important;
}
.grid {
  margin: 0px;
  .dialoggrid {
    border: 1px solid #c7c7c7;
    padding: 20px 10px;
    text-align: center;
    color: #495057;
  }
}
.leave-history-btn {
  padding: 17px 22px;
  color: #ffffff;
  background-color: #c7c7c7;
  color: #000210;
  border-radius: 12px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
}
.leave-history-btn-active {
  background-color: #5a78ee;
  color: #ffffff;
  padding: 17px 22px;
  color: #ffffff;
  border-radius: 12px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
}

.emp-name {
  margin-top: -35px !important;
}
.table-alignment {
  margin-top: -22px;
}
.p-datatable-wrapper {
  border-radius: 15px;
}
.holiday-add {
  height: 36px !important;
  margin-right: -170px !important;
}
.leave-action-btn {
  margin-left: 8px !important;
}
.circular--portrait {
  position: relative;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 3rem;
}
.circular--portrait img {
  width: 100%;
  height: auto;
}

.remove-image-btn {
  height: 40px !important;
  width: 186px;
  margin-left: 112px;
}
.asset-name-align {
  width: 200px !important;
  word-wrap: break-word !important;
}
.holiday-id-align {
  padding-left: 2rem !important;
}
