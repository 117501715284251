.releasecard {
    width: 35%;
    height: 300px;
    background-color:#FFFFFF;
    border-radius: 15px;
}

.releasecard h1 {
    color: #252B30;
    font-weight: 700;
    font-family: "Poppins" !important;
    font-size: 22px;
    line-height: 56px;
}

.releasecard p {
    color: #A4A8AE;
    font-family: "Poppins" !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
}

.saveimage {
    width: 10%;
    color: #82858D;
}

.chartimage {
    width: 10%;
    color: #82858D;
    margin-left: 64px;
}

.loadingbar {
    width: 60%;
    background-color: #5A78EE !important;
    height: 15px;
    border-radius: 12px;
}

.loadingcard {
    display: flex;
}

.loadingpercent {
    margin-top: -7px;
    color: #82858D;
    font-weight: 600;
}
.datecard {
    text-align: center;
    color: #8C8C8C;
    font-family: "Poppins" !important;
    font-weight: 600;
    background-color: rgba(177, 177, 177, 0.12);
    width: 30%;
    height: 38px;
    border-radius: 10px;
    line-height: 38px
}
.completed{
    color: #FAFCFF;
    background: #02D5A3;
    width: 35%;
    height: 38px;
    border-radius: 22px;
    margin-left: 82px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 17px;
    line-height: 37px;
    text-align: center;
}
.progress{
    color: #FAFCFF;
    background:#F27E11;
    width: 35%;
    height: 38px;
    border-radius: 22px;
    margin-left: 82px;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 17px;
    line-height: 37px;
    text-align: center;
}