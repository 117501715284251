
@mixin theme-color {
  background-color: #ff006d ;
}

@mixin border-radius {
  border-top-left-radius: 20px ;
  border-bottom-left-radius: 20px ;
}


.sidebar {
  @include theme-color;
  height: 100vh;
  float: left;
}

.h-screen {
  background: #ffffff !important;
  @include border-radius 
}

.logo {
  margin-top: 59px;
  margin-left: 20px;
}


.password-text {
  width: 100%;
}

.error-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  color: #ff3333;
}




.outline-app{
  background: #F5056C;
}

.layout{
  background: #ffffff !important;
  @include border-radius
}

.nav__progress .p-progressbar-value{
  background: #F5056C;
}
