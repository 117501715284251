.error__theme {
    color: #FF006D
}

.error__container{
    background: #FFD1E5;
    border-top: 4px solid #FF006D;
}

.error__title {
    color: #2D3748;
    font-size: 1rem;
    font-weight: 600;
}