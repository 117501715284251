.chart-contaniner {
  background: #ffffff;
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
}

.color-container {
  position: absolute;
  width: 8px;
  height: 10px;
  background: #DDEFF4;
  border-radius: 3px;
}
.occupied-percentage {
  margin-top: 11px !important;
  margin-left: 40px !important;
}
.requested-percentage {
  margin-top: 16px !important;
  margin-left: 208px !important;
}
.unassigned-percentage {
  margin-top: 16px !important;
    margin-left: 208px !important;
}

.requested-asset-font {
  margin-left:8rem;
  margin-top: 11px !important;
}
.unassingned-asset-font {
  margin-top: 11px !important;
  margin-left: 9.1rem !important;
}
.p-chart {
  height: 150px !important;
}
.pie-chart-data-font {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  color: #1F1F1F;
}

/* #F32D7D", "#F3AB29", "#4CB8F1", "#FA5001 */

.occupied-color {
  height: 17px;
  width: 15px;
  /* background: linear-gradient(180deg, #EC008C 0%, #FC6767 100%); */
  background: #F32D7D;
  border-radius: 3px;
}
.requested-color {
  height: 17px;
  width: 15px;
  /* background: linear-gradient(180deg, #FF8008 0%, #FFC837 100%); */
  background: #F3AB29;
  border-radius: 3px;
}
.retired-color {
  height: 17px;
  width: 15px;
  /* background: linear-gradient(180deg, #56CCF2 0%, #2F80ED 100%); */
  background-color: #4CB8F1;
  border-radius: 3px;
}
.unassigned-color {
  height: 17px;
  width: 15px;
  /* background: linear-gradient(180deg, #FE8C00 0%, #F83600 100%); */
  background: #FA5001;
  border-radius: 3px;
}
.leave-color {
    height: 17px;
    width: 15px;
    /* background: linear-gradient(180deg, #56CCF2 0%, #2F80ED 100%); */
    background: #4CB8F1;
    border-radius: 3px;
}
.working-color {
  height: 17px;
  width: 15px;
  /* background: linear-gradient(180deg, #EC008C 0%, #FC6767 100%); */
  background: #F32D7D;
  border-radius: 3px;
}
.chart-data-align {
  margin-left: 6.2rem;
}
.leavechart-leavepercentage {
margin-left: 14rem;
}
.leavechart-workingpercentage {
  margin-left: 13.2rem;
  }

.chart {
  width:240px;
  height:126px;
  background: #ffffff;
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
}
.dashboard_payslip {
  width: 350px;
  height:126px;
  background: #ffffff;
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
}
.payroll_detils {
  width:226px;
  height:265px;
  background: #ffffff;
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.distribution{
  width:316px;
  height:265px;
  background: #ffffff;
  border: 1px solid #f2f4f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  align-items: center;
  padding: 10px;
}
.gap{
  display: flex;
  gap: 1rem;
  /* justify-content: space-between; */
  margin: 0 2rem;
}
.payslip_flex{
  display: flex;
  gap:1rem;
}
.payslip_employee{
  display: flex;
  gap:2.5rem;
}
.chart_filter{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.payslip_header_font2{
  color: #1F1F1F;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  /* gap: 5rem; */
}
.payslip_values{
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  display: flex;
  gap: 2px;
}
.payslip_arrow{
  display: flex;
  gap: 0.5rem;
}
.working_hours{
  position: relative;
  left: 1rem;
}
.hours{
  font-size: 20px;
  font-weight: 600;
}
.month{
  color:#82858D;
  font-size: 12px;
}
.average{
  width: 70%;
  /* font-size: 19px; */
}
.wallet{
  position: absolute;
  top: 1.1rem;
  left: 1.1rem;
}
.wallet_img{
  position: relative;
}
.center{
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.5rem;
}
.center h5{
  color:#82858D;
  font-size: 12px;
}
.payroll_date{
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}
.salary_font{
  font-size: 16px;
  font-weight: 600;
  color: #1F1F1F;
}
.salary_img{
  display: flex;
  margin-left:3.6rem;
}

.distribution h5{
  position: relative;
  display: flex;
  left: -20px;
}

.section_gap{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.payroll_search{
  display: flex;
  justify-content: end;
}